var msg: Translations = {
    DELETEALLPRODUCTSERROR: 'Error deleting products from cart',
    ERR_CARRIERMISSING: 'You must select a shipping type',
    ERR_CARRIER_COST_MISSING: 'No applicable rate for country, courier and order weight.',
    ERR_CLIENTMANDATORYFIELDS: 'Marked fields are required',
    ERR_DATE_FROM: 'The date must be subsequent to :from',
    ERR_DATE_FROM_TO: 'Date must be between :from and :to',
    ERR_DATE_TO: "The date must be prior to :to",
    ERR_FILLEMAILFIELD: 'Fill in the email field',
    ERR_INVALIDMAIL: 'Invalid email',
    ERR_LIGHTBOX_LOADING: 'Image detail loading, try again in a few seconds',
    ERR_LINK_NOT_COPIED: 'The link cannot be copied.',
    ERR_LOGININVALID: 'Invalid email and/or password',
    ERR_LOGINREQUIRED: 'You need to login',
    ERR_MISSINGFIELDS: 'Complete all required fields correctly',
    ERR_NOSIZESELECTED: 'You must first select a size',
    ERR_PEC_OR_SDI: 'Fill in at least one field between PEC and SDI',
    ERR_PRIVACY: 'You must accept the conditions',
    ERR_USEREXISTS: 'Existing user.',
    ERR_VAT: 'Incorrect VAT number format',
    ERR_VAT_PREFIX: 'VAT ID must start with the country code (e.g. IT)',
    LABEL_ALREADYREGISTEREDUSERES: 'Already registered users',
    LABEL_ARTICLES: 'articles',
    LABEL_CANCEL: 'delete',
    LABEL_CONFIRM: 'Confirm',
    LABEL_INVITES_ALREADY_SENT: 'Already invited users',
    LABEL_NOTAPPLICABLE: 'Not applicable',
    LABEL_NOTSENTINVITES: 'Invitations not sent',
    LABEL_SELECTALL: 'Select all',
    LABEL_SENTINVITES: 'Sent invitations',
    LABEL_SUBMIT: 'Send',
    LABEL_WARNING: 'Warning',
    MSG_ADDEDTOCART: 'Products added to cart',
    MSG_ADDEDTOWISHLIST: 'Product added to wishlist',
    MSG_APPLIEDVATCHANGED: 'After this billing information update, the VAT applied to your order has changed.',
    MSG_COUPON_ERROR: 'Invalid or previously entered coupon code.',
    MSG_COUPON_VALID: 'Coupon code entered successfully validated.',
    MSG_DEFERRED_DISPATCH_COMPUTATION: 'Only order reservation will be enabled and shipping charges will be notified by email.',
    MSG_DELETEALLPRODUCTS: 'Are you sure you want to remove all products from the cart?',
    MSG_EXTRAUELIMIT: 'The minimum amount of your order cannot be less than 2000.00 &euro;',
    MSG_GENERIC_ERROR: 'Sorry, an error has occurred.',
    MSG_INSUFFICIENT_STOCK: 'There is currently insufficient product availability.',
    MSG_INVALID_CAPTCHA: 'Invalid captcha code',
    MSG_LINK_COPIED: 'Copied link',
    MSG_MAILSENT: 'E-Mail sent',
    MSG_MESSAGE_PRODUCTSHARE: 'Message',
    MSG_MESSAGE_SENT: 'Message sent!',
    MSG_MIXED_CART: 'The products in the cart cannot be purchased together with the selected product because they are in different warehouses.',
    MSG_MIXED_INTANGIBLE: 'Products in the cart cannot be purchased together with the selected product.',
    MSG_MUST_ACCEPT_TERMS: 'You must accept the terms of use.',
    MSG_NEWSLETTER_COMPLETED: 'Enrollment successfully completed',
    MSG_PASSWORD_NOT_COMPLEX: 'The new password is not complex enough, it must include at least 1 lowercase character, 1 uppercase character and 1 number and be at least 8 characters long.',
    MSG_PASSWORD_NOT_EQUAL: 'The two passwords do not match.',
    MSG_PASSWORD_OK: 'The password has been changed',
    MSG_PASSWORD_RESET: 'The password was successfully reset',
    MSG_PRIVACY_POLICY: 'You must accept the privacy policy',
    MSG_PROFILECONSENT_SAVED: 'Your contact preferences have been updated',
    MSG_RECOVEREMAILSENT: 'Check your email and click on the link to confirm the change.',
    MSG_RECOVEREMAILSENT_ERROR: 'Warning: You are not registered with this email address or are already resetting a new password.',
    MSG_REMOVEDFROMWISHLIST: 'Product removed from wishlist',
    MSG_RETURN_REQUEST: 'Return request completed successfully',
    MSG_RETURN_REQUEST_ERROR: 'Return request cannot be completed, contact the administrator',
    MSG_RETURN_REQUEST_SUCCESS: 'Return request created with success',
    MSG_RULE_REJECT: 'The product cannot be added to the cart because of the :rule',
    MSG_SAVE_SUCCESS: 'Saved successfully',
    MSG_SELECT_PROVINCE: 'Select your province',
    MSG_SENDER_PRODUCTSHARE: 'Your name',
    MSG_SUBJECT_PRODUCTSHARE: ' shared something with you',
    MSG_SUBSCRIBED: 'successfully subscribed',
    MSG_SUBSCRIPTION_FAILURE: 'Make sure you do not have any other active subscription.',
    MSG_SUBSCRIPTION_NO_RENEWABLE: 'Too many days have passed so the subscription is no longer renewable',
    MSG_TOKEN_SENT: 'We just sent a code to your email. Please check your mail to see if you received the message that was sent to you and enter the code here',
    MSG_USER_ALREADY_REGISTERED: 'User already registered',
    MSG_USER_NOT_FOUND: 'User not registered',
    MSG_WAIT: 'Please wait...',
    MSG_WRONG_PASSWORD: 'The password entered is incorrect'
}